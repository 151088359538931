import { getSpecificBadge } from "../../Services/apiClient";
import { Helmet } from "react-helmet";
import { useState, useEffect } from "react";
import styled from "styled-components";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import {
  // BrowserRouter as Router,
  // Switch,
  // Route,
  // Link,
  useParams,
} from "react-router-dom";

import { getUser } from "../../Services/apiClient";
//images
import badge4 from "../../badge4.png";
import logo from "../../assets/geshdologoWhite.png";

function SpecificBadge() {
  const [badge, setBadge] = useState([]);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const [currentUser, setCurrentUser] = useState({});

  const getCurrentUser = async () => {
    const user = await getUser("firestore/whoAmI");

    setCurrentUser(user);
  };

  useEffect(() => {
    getSpecificBadge("badge", id).then(
      (specificBadgesFromDb) => setBadge(specificBadgesFromDb),
      setTimeout(() => {
        setLoading(true);
      }, 1500)
    );
    getCurrentUser();
  }, []);

  //console.log(loading);

  if (loading) {
    return (
      <>
        {id && badge && badge.receiver && (
          <>
            <Helmet>
              <meta
                property="og:title"
                content={badge.name}
                data-react-helmet="true"
              />
              <meta
                property="og:image"
                content="https://geshdo.com/images/geshdo.jpg"
                data-react-helmet="true"
              />
              <meta
                property="og:description"
                content="This specific badge info"
                data-react-helmet="true"
              />
              <meta
                property="og:url"
                content={badge.externalURL}
                data-react-helmet="true"
              />
              <meta
                property="og:type"
                content="website"
                data-react-helmet="true"
              />
            </Helmet>
            <StyledOverallViewContainer>
              <div className="hero">
                {/* <div className='grid'> */}
                <div className="cardSpecific">
                  <div className="img_and_cardinfo">
                    <img src={badge.img || badge4} alt="badge" />
                    <div className="card-info">
                      <p>{badge.date}</p>

                      <p>
                        {badge.name}{" "}
                        <span style={{ fontSize: "1rem" }}>is handed to:</span>
                      </p>
                      <p>
                        {badge.receiver
                          .split("@")[0]
                          .replace(".", " ")
                          .replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
                            letter.toUpperCase()
                          )}
                      </p>
                      <p>{badge.info}</p>
                    </div>
                  </div>
                  <div className="bannerSpecific">
                    <img className="logo" src={logo} alt="logo" />
                  </div>
                </div>
              </div>
              {/* </div> */}
            </StyledOverallViewContainer>
          </>
        )}
      </>
    );
  } else {
    return (
      <Box
        sx={{
          height: "100vh",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress color="secondary" />
      </Box>
    );
  }
}

const StyledOverallViewContainer = styled.div`
  height: 100vh;
  display: flex;

  .hero {
    display: grid;

    padding: 4rem;
    width: 50vw;
    height: 70vh;

    border-radius: 20px;

    margin: auto;

    @media (max-width: 480px) {
      width: 95vw;
      height: 55vh;
      border: none;
    }
  }
  .grid {
    /* display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 4rem;
    margin: 5rem 2rem; */
  }
  .cardSpecific {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: white;
    border-radius: 10px;
    border: 2px solid #7eaccc;
    @media (max-width: 768px) {
      height: 90%;
    }
    @media (max-width: 480px) {
      height: 80%;
      margin-top: 0.5rem;
    }
  }
  .img_and_cardinfo {
    height: 50%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 1rem;
    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
      padding: 0.5rem;
    }
    @media (max-width: 480px) {
      margin-top: 1rem;
    }
  }
  .card-info {
    background: #1b1b1b;
    padding: 1rem;
    height: 30vh;
    border-radius: 5px;
    color: #fdfdfd;
    margin-left: 2rem;
    @media (max-width: 768px) {
      margin: 2rem 0 0 0;
    }
    @media (max-width: 480px) {
      margin-top: 2rem;
      height: 22vh;
      width: 80%;
      padding: 1.5rem;
    }
  }
  p {
    padding-bottom: 5px;
    font-size: 0.9rem;
  }
  p:first-child {
    font-size: 0.7rem;
    font-weight: 600;
  }

  p:nth-child(2) {
    font-size: 1.2rem;
    font-weight: 600;
  }
  p:nth-child(3) {
    font-size: 1.2rem;
    font-weight: 600;
  }

  img {
    width: 30%;
  }
  .bannerSpecific {
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(-135deg, #7eaccc, #1dc4e9);

    height: 10vh;
    width: 100%;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }
  .logo {
    width: 25%;
    @media (max-width: 480px) {
      width: 50%;
    }
  }
`;

export default SpecificBadge;
