import React from 'react';
import { useMsal } from '@azure/msal-react';
import { authRequest } from '..';
import styled from 'styled-components';

function handleLogin(instance) {
  instance.loginRedirect(authRequest).catch((e) => {
    console.error(e);
  });
}

/**
 * Renders a button which, when selected, will redirect the page to the login prompt
 */
export const SignInButton = () => {
  const { instance } = useMsal();

  return (
    <>
  {!window.location.href.includes("specificbadge") && ( <StyledBtn
      variant='secondary'
      className='ml-auto'
      onClick={() => handleLogin(instance)}
    >
      Sign in
    </StyledBtn> )}
    </>
  );
};

const StyledBtn = styled.button`
  position: relative;
  display: inline-block;
  margin: 15px;
  padding: 15px 30px;
  text-align: center;
  font-size: 1.7rem;
  letter-spacing: 1px;
  text-decoration: none;
  color: black;
  background: transparent;
  cursor: pointer;
  transition: ease-out 0.5s;
  border: 2px solid black;
  border-radius: 10px;
  box-shadow: inset 0 0 0 0 black;
  margin-top: 45vh;
  margin-left:45vw;
  &:hover {
    color: white;
    box-shadow: inset 0 -100px 0 0 black;
  }
  &:active {
    transform: scale(0.9);
  }

  @media (min-width: 2000px) {
    font-size: 2.7rem;
  }
`;
