import { getUserBadges } from "../../Services/apiClient";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import styled from "styled-components";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { motion, useAnimation } from "framer-motion";
import Filter from "./Filter";
import { useParams } from "react-router-dom";

//images

import logo from "../../assets/geshdologoWhite.png";
//import { styled } from '@mui/material/styles';

function UserView() {
  const [badges, setBadges] = useState([]);
  const [loading, setLoading] = useState(false);
  const [year, setYear] = useState("any");
  const { id } = useParams();
  let navigate = useNavigate();

  const getFilterData = (data) => {
    setYear(data.year);
  };
  console.log("this is id", id);
  useEffect(() => {
    getUserBadges(`firestore/userBadges/${year}`).then(
      (usersFromDb) => setBadges(usersFromDb),
      setTimeout(() => {
        setLoading(true);
      }, 1500)
    );
  }, [year]);
  console.log(badges);

  // const lastItem = badges.map((item) =>
  //   item.externalUrl.substring(item.externalUrl.lastIndexOf('/') + 1)
  // );

  if (loading) {
    return (
      <>
        <StyleUserViewContainer>
          <div className="hero">
            <Filter getData={getFilterData} />
            <motion.div className="grid">
              {badges.map((badge, idx) => (
                <div key={idx}>
                  <motion.div
                    initial={{ y: 100, opacity: 0 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{
                      type: "spring",
                      stiffness: 60,
                    }}
                    viewport={{ once: true }}
                    className="card"
                    onClick={() =>
                      navigate(
                        `/specificbadge/${badge.externalURL.substring(
                          badge.externalURL.lastIndexOf("/") + 1
                        )}`
                      )
                    }
                  >
                    <div className="img_and_cardinfo">
                      <img src={badge.img} alt="badge" />
                      <div className="card-info">
                        <p>{badge.date}</p>
                        <p>
                          {badge.name}{" "}
                          <span style={{ fontSize: "0.8rem" }}>
                            Badge is handed to:
                          </span>
                        </p>
                        <p>
                          {/* js code for extracting name from email */}
                          {badge.receiver
                            .split("@")[0]
                            .replace(".", " ")
                            .replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
                              letter.toUpperCase()
                            )}
                        </p>
                      </div>
                    </div>
                    <div className="banner">
                      <img className="logo" src={logo} alt="logo" />
                    </div>
                  </motion.div>
                </div>
              ))}
            </motion.div>
          </div>
        </StyleUserViewContainer>
      </>
    );
  } else {
    return (
      <Box
        sx={{
          height: "100vh",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress color="secondary" />
      </Box>
    );
  }
}

const StyleUserViewContainer = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;

  .hero {
    overflow: auto;

    width: 90vw;
    height: 80vh;

    border-radius: 20px;

    margin: 3rem auto;
  }
  .grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 4rem;
    margin: 5rem 2rem;
  }
  .card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 18rem;
    border-radius: 5px;
    background: #fdfdfd;
    border: 2px solid #9545b1;
  }
  .img_and_cardinfo {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    padding: 1rem;
  }
  .card-info {
    background: #1b1b1b;
    padding: 1rem;
    border-radius: 5px;
    color: #fdfdfd;
    margin-left: 2rem;
  }
  p {
    padding-bottom: 5px;
    font-size: 1rem;
  }
  p:first-child {
    font-size: 0.8rem;
  }
  p:nth-child(3) {
    font-size: 1rem;
  }
  img {
    width: 6rem;
  }
  .banner {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #9545b1;
    height: 4rem;
    width: 100%;
  }
  .logo {
    width: 40%;
  }
`;

export default UserView;
