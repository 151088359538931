import {useState, useEffect} from "react"
import TemplateView from "../Template/TemplateView";
import { getUser } from "../../Services/apiClient";
import OverallView from "../OverallView";

function LandingPage() {
  const [currentUser, setCurrentUser]=useState({})
  const getCurrentUser = async () => {
    const user = await getUser("firestore/whoAmI");

    setCurrentUser(user);
    return user;
  };
useEffect(()=>{
getCurrentUser()
},[])

  return <>
  {currentUser.role && currentUser.role==="admin" && (<TemplateView/>)}
  {currentUser.role && currentUser.role==="user" && (<OverallView/>)}
  
  </>;
}

export default LandingPage;

