import React from "react";
import { getSpecificTemplate } from "../../Services/apiClient";
import styled from "styled-components";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { useParams } from "react-router-dom";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useState, useEffect } from "react";
import { m, motion, useAnimation } from "framer-motion";
import logo from "../../assets/geshdologoWhite.png";
import Button from "@mui/material/Button";
import { addReceivers } from "../../Services/apiClient";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";

const SpecificTemplate = () => {
  const [template, setTemplate] = useState({});
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [receivers, setReceivers] = useState([]);
  const [open, setOpen] = useState(false);
  const [newReceiver, setNewReceiver] = useState({"name":"", date:new Date()});
  const [success, setSuccess] = useState(null);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const addNewReceiver = () => {
    if(newReceiver.name!==""){
       setReceivers([...receivers, newReceiver]);
    setNewReceiver({"name":"", date:new Date()});
    }
   
  };
  const addReceiversToBadge = async () => {
    const result = await addReceivers("firestore/badges/" + id, receivers);
    if (result.includes("Successfully")) {
      setReceivers([]);
      setSuccess(true);
    } else {
      setSuccess(false);
    }
    setTimeout(() => {
      setSuccess(null);
    }, "4000");
  };

  //sets value for date picker
  const handleDateChange = (value, receiver) => {
    const index=receivers.indexOf(receiver)
   const newArr=Object.assign([], receivers)
   newArr[index].date=value
   setReceivers([...newArr])

  };

 

  useEffect(() => {
    getSpecificTemplate("firestore/templates/" + id).then(
      (template) => setTemplate(template),
      setTimeout(() => {
        setLoading(true);
      }, 1500)
    );
  }, []);

  if (loading) {
    return (
      <>
        {template && template.date && (
          <>
            <StyledOverallViewContainer>
              <div className="hero">
                {/* <div className='grid'> */}
                <div className="card">
                  <div className="img_and_cardinfo">
                    <img src={template.img} alt="badge" />
                    <div className="card-info">
                      <p>{template.date}</p>

                      <p>{template.name} </p>

                      <p>{template.info}</p>
                    </div>
                  </div>
                  <div className="banner">
                    <img className="logo" src={logo} alt="logo" />
                  </div>
                </div>
                <div className="addReceivers" style={{ paddingTop: "5vh" }}>
                  <Button variant="contained" onClick={() => handleOpen()}>
                    Issue this badge
                  </Button>
                </div>
              </div>
            </StyledOverallViewContainer>

            {/* modal starts here */}
            <div>
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            
              >
                <Box sx={style}>
                  <div className="addReceivers">
                    <h3>Add receivers</h3>
                    <div
                      style={{
                        paddingTop: "3vh",
                        display: "flex",
                        itemsAlign: "center",
                        justifyContent: "center",
                   
                      }}
                    >
                      <TextField
                        id="outlined-basic"
                        label="Receiver email"
                        variant="outlined"
                        sx={{width:"50%"}}
                        value={newReceiver.name}
                        onChange={(e) =>
                          setNewReceiver({
                            name: e.target.value,
                            date: new Date(),
                          })
                        }
                      />
                      <Button onClick={() => addNewReceiver()}>Add</Button>
                    </div>
                  </div>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    {receivers.length > 0 && (
                      <>
                      <div
                        className="receiversList"
                        style={{ marginTop: "5vh", maxHeight:"50vh", overflowY:"scroll"}}
                      >
                        <p>Receivers ({receivers.length}):</p>
                        {receivers.map((receiver, id) => (
                          <div
                          className="receiver"
                            key={id}
                            style={{
                              display: "grid",
                              gridTemplateColumns:"10% 40% 50%",
                              gap: "1vw",
                              paddingTop: "3vh",
                            alignItems:"center",
                            padding:"1vw 2vw 1vw 2vw"
                         
                            }}
                          >
                            <img
                              src="https://cdn-icons-png.flaticon.com/512/3024/3024525.png"
                              style={{ height: "3vh", cursor: "pointer" }}
                              alt=""
                              onClick={() =>
                                setReceivers(
                                  receivers.filter(
                                    (rec) => rec.name !== receiver.name
                                  )
                                )
                              }
                            />
                            <p>{receiver.name}</p>
                            <DatePicker
                              label="Issue date"
                              inputFormat="MM/dd/yyyy"
                             className={receiver.name}
                              value={receivers[receivers.indexOf(receiver)].date}
                              onChange={(value)=>handleDateChange(value, receiver)}
                             
                              renderInput={(params) => {
                                
                                return (
                                  <StyledTextField
                                   
                                    focused
                                    
                                    value={params.inputProps.value}
                                    {...params}
                                    style={{
                                      color: "black",
                                      borderRadius: "10px",
                                      
                                    }}
                                  />
                                );
                              }}
                            />
                          </div>
                        ))}
                        <div
                          className="doneBtn"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "8vh",
                          }}
                        >
                         
                        </div>
                      </div>
                       <Button
                            variant="contained"
                            onClick={() => addReceiversToBadge()}
                            sx={{background: "linear-gradient(-135deg,#899fd4,#a389d4)"}}
                          >
                            Done
                          </Button>
                          </>
                    )}
                    <div className="result" style={{ textAlign: "center" }}>
                      {success === true && (
                        <p style={{ color: "green" }}>
                          Badge has been successfully issued
                        </p>
                      )}
                      {success === false && (
                        <p style={{ color: "red" }}>Something went wrong :(</p>
                      )}
                    </div>
                  </Typography>
                </Box>
              </Modal>
            </div>

            {/* end of modal */}
          </>
        )}
      </>
    );
  } else {
    return (
      <Box
        sx={{
          height: "100vh",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress color="secondary" />
      </Box>
    );
  }
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 700,
  bgcolor: "background.paper",
  borderRadius: "5px",
  boxShadow: 24,
  p: 4,
 
};

const StyledOverallViewContainer = styled.div`
  height: 100vh;
  display: flex;

  .hero {
    display: grid;

    padding: 4rem;
    width: 50vw;
    height: 70vh;

    border-radius: 20px;

    margin: auto;

    @media (max-width: 480px) {
      width: 95vw;
      height: 55vh;
      border: none;
    }
  }
  .grid {
    /* display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 4rem;
    margin: 5rem 2rem; */
  }
  .card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #fdfdfd;
    border-radius: 10px;
    border: 2px solid #899fd4;
   
    @media (max-width: 768px) {
      height: 90%;
    }
    @media (max-width: 480px) {
      height: 80%;
      margin-top: 0.5rem;
    }
  }
  .img_and_cardinfo {
    height: 50%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 1rem;
    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
      padding: 0.5rem;
    }
    @media (max-width: 480px) {
      margin-top: 1rem;
    }
  }
  .card-info {
    background: #1b1b1b;
    padding: 1rem;
    height: 30vh;
    border-radius: 5px;
    min-width: 40%;
    color: #fdfdfd;
    margin-left: 2rem;
    @media (max-width: 768px) {
      margin: 2rem 0 0 0;
    }
    @media (max-width: 480px) {
      margin-top: 2rem;
      height: 22vh;
      width: 80%;
      padding: 1.5rem;
    }
  }
  p {
    padding-bottom: 5px;
    font-size: 0.9rem;
  }
  p:first-child {
    font-size: 0.7rem;
    font-weight: 600;
  }

  p:nth-child(2) {
    font-size: 1.2rem;
    font-weight: 600;
  }
  p:nth-child(3) {
    font-size: 1.2rem;
    font-weight: 600;
  }

  img {
    width: 30%;
  }
  .banner {
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(-135deg,#899fd4,#a389d4);
    height: 10vh;
    width: 100%;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }
  .logo {
    width: 25%;
    @media (max-width: 480px) {
      width: 50%;
    }
  }
`;

const StyledTextField = styled(TextField)``;
export default SpecificTemplate;
