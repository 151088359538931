import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import modalIcon from "../../assets/badgeIcon.png";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import ClipboardCopy from "../ClipboardCopy/ClipboardCopy";

//Modal
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
//MUI
import styled from "styled-components";
import TextField from "@mui/material/TextField";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import CheckIcon from "@mui/icons-material/Check";
import { postBadge } from "../../Services/apiClient";
import { getUserBadges } from "../../Services/apiClient";
//import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { getUser } from "../../Services/apiClient";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import BadgePreview from "./badgePreview";
//Breakpoints
import breakPoints from "../../breakpoints";
console.log(breakPoints.device.s);
function CreateBadge() {
  const [open, setOpen] = useState(false);
  const [openWrong, setOpenWrong] = useState(false);
  let navigate = useNavigate();
  const [state, setState] = useState({
    badgename: "",
    info: "",
    date: new Date(),
    image: "",

    templateId: "",
  });

  //Modal
  const handleOpen = () => setOpen(true);
  //need to also resen uploader
  const handleClose = () => {
    setState({
      ...state,
      date: new Date(),
      templateId: "",
      badgename: "",
      info: "",
      image: "",
    });
    setOpen(false);
    navigate("/specificTemplate/" + state.templateId);
  };

  //console.log('this is the date', value);

  const handleChange = (e) => {
    e.preventDefault();
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const postNewBadge = async () => {
    if (state.badgename === "" || state.image === "") {
      setOpenWrong(true);
    } else {
      const formattedDate = state.date.toISOString().slice(0, 10);

      const result = await postBadge(
        "firestore/templates",
        state.badgename,
        formattedDate,

        state.info,
        state.image
      );

      console.log(result);
      setState({ ...state, templateId: result });
      handleOpen();
    }
  };

  const getCurrentUser = async () => {
    const user = await getUser("firestore/whoAmI");
    setState({ ...state, currentUser: user });
  };

  useEffect(() => {
    getCurrentUser();
  }, []);

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  const handleFileRead = async (event) => {
    const file = event.target.files[0];
    const base64 = await convertBase64(file);
    /* console.log(base64) */
    setState({ ...state, image: base64 });
    event.target.value = null;
  };

  const handleCloseWrong = () => {
    setOpenWrong(false);
  };
  return (
    <>
      {state.currentUser && state.currentUser.role === "admin" && (
        <>
          <div>
            {/* Modal */}
            <Modal
              open={open}
              onClose={handleClose}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={open}>
                <Box sx={style}>
                  <Typography
                    id="transition-modal-title"
                    variant="h4"
                    component="h2"
                    mt={3}
                    mb={5}
                    sx={{
                      color: "#1f1f1f",
                      fontSize: "1.3rem",
                      fontWeight: "500",
                    }}
                  >
                    The badge was successfully created!{" "}
                  </Typography>

                  <img
                    style={{ width: "30%" }}
                    id="badge-icon"
                    src={modalIcon}
                    alt="badge-icon"
                  />
                </Box>
              </Fade>
            </Modal>
          </div>
          {/* Modal ends here */}
          {/*Warning modal starts */}
          <div>
            <Modal
              open={openWrong}
              onClose={handleCloseWrong}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={styleWarning}>
                <Typography id="modal-modal-title" variant="h4" component="h2">
                  Wrong information
                </Typography>
                <Typography
                  id="modal-modal-description"
                  sx={{ mt: 2, fontSize: "1.5em" }}
                >
                  Please, fill in all the fields in the form
                </Typography>
              </Box>
            </Modal>
          </div>
          {/*Warning modal ends */}
          <h1 style={{ padding: "4vh 0vh 0vh 4vw", fontWeight:"400", color:"black", fontSize:"2em"}}>Create badge template</h1>
          <StyledHomeContainer>
            <div className="hero">
              {/* <button onClick={postNewBadge}>Send</button>{' '} */}
              <div className="form">
                <form>
                  <div className="inputbox">
                    <StyledTextField
                        sx={{
                          "& .MuiInputBase-input": {
                          
                            backgroundColor: "white",
                          
                            
                          },
                          "& .MuiInputLabel-root":{
                            color:"rgb(118, 118, 118) !important"
                          }
                         
                        }}
                      required
                      color="secondary"
                      focused
                      className="input"
                      id="outlined-basic"
                      label="Badge Name"
                      variant="outlined"
                      type="text"
                      name="badgename"
                      // helperText='Name of the badge'
                      value={state.badgename}
                      onChange={handleChange}
                    />
                  </div>
                  <div>
                    <div className="input_and_img">
                      <TextareaAutosize
                        type="text"
                        label="info"
                        required
                        name="info"
                        value={state.info}
                        variant="standard"
                        placeholder="Information about badge here..."
                        onChange={handleChange}
                        //InputProps={{ style: { fontSize: '4rem' } }}
                        style={{
                          width: 400,
                          height: 100,
                          borderColor: "#d9d8d8",

                          borderWidth: 1,
                          marginBottom: "3rem",

                          borderRadius: "10px",
                          padding: "10px",
                          fontSize: "1rem",
                          fontFamily: "Roboto",
                        }}
                      />
                    </div>

                    <div className="button-box">
                      <Button
                        startIcon={<UploadFileIcon />}
                        variant="contained"
                        component="label"
                        sx={{
                          marginBottom: "2rem",
                          background: "#5295BF",
                          "&:hover": {
                            background: "#3e7394",
                          },
                        }}
                      >
                        Upload file
                        <TextField
                          id="originalFileName"
                          type="file"
                          inputProps={{ accept: "image/*, .pdf, .png" }}
                          required
                          label="Document"
                          name="originalFileName"
                          onChange={(e) => handleFileRead(e)}
                          // size='small'
                          variant="standard"
                          sx={{ display: "none" }}
                        />
                      </Button>
                      <Button
                        sx={{
                          padding: 1.5,
                          width: "16rem",
                          color: "#fdfdfd",
                          background: "linear-gradient(-135deg, rgb(137, 159, 212), rgb(163, 137, 212));",
                          "&:hover": {
                            background: "#932f78",
                          },
                        }}
                        // type='submit'
                        onClick={postNewBadge}
                        // color='secondary'
                        variant="contained"
                        // style={{ border: '2px solid' }}
                        startIcon={<CheckIcon />}
                      >
                        Create Badge
                      </Button>
                    </div>
                  </div>
                </form>
              </div>
              <div className="preview">
                <BadgePreview
                  name={state.badgename}
                  date={state.date.toISOString().slice(0, 10)}
                  info={state.info}
                  img={state.image}
                />
              </div>
            </div>
          </StyledHomeContainer>
        </>
      )}

      {state.currentUser && state.currentUser.role === "user" && (
        <>
          <div className="noPerm" style={style.noPerm}>
            <h5 style={{ color: "white" }}>
              Oops, seems like you don't have permission to view this page
            </h5>
          </div>
        </>
      )}
    </>
  );
}

const style = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  boxShadow: 24,
  width: 450,
  height: 400,
  backgroundColor: "#fdfdfd !important",
  p: 4,
  borderRadius: "10px",
  
  color: "#0d0d0d !important",
  img: {
    width: "100%",
  },
  noPerm: {
    textAlign: "center",
    fontSize: "5vh",

    marginTop: "45vh",
  },
};

const styleWarning = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  boxShadow: 24,
  textAlign: "center",

  backgroundColor: "#fdfdfd !important",
  p: 4,
  borderRadius: "10px",
  backgroundColor:"white",
 
  color: "#0d0d0d !important",
};

const StyledHomeContainer = styled.div`
  height: 100vh;
  display: flex;
  /* align-items: center; */
  justify-content: center;

  svg {
    color: #fdfdfd;
  }
  .inputbox {
    display: flex;
  }
  .hero {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    align-items: start;

    padding: 4rem;
    width: 90vw;
    height: 80vh;
    gap: 10vw;

    border-radius: 20px;

    margin: auto;
  }

  .input {
    margin: 0 3rem 3rem 0;
  }

  .input_and_img {
    display: flex;
    width: 50vh;
    margin-top: 2.5rem;
  }

  .uploaded-img-box {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
    width: 8rem;
    border-radius: 5px;
    background: #fdfdfd;
    margin-left: 4rem;
  }

  .uploaded-image {
    width: 80%;
    padding: 0.9rem;
  }

  .card {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fdfdfd;
    border-radius: 5px;
    height: 7rem;
    padding: 3rem;
  }

  .badge {
    width: 3rem;
  }

  .button-box {
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }
  //Breakpoints
  @media ${breakPoints.device.xs} {
    .hero {
      padding: 1rem 1.2rem;
    }
    .input_and_img {
      flex-direction: column;
      width: 100%;
    }
    .uploaded-img-box {
      margin: 0 0 2rem 0;
      height: 60px;
    }
    .uploaded-image {
      width: 80%;
    }
  }

  @media ${breakPoints.device.s} {
    .hero {
      width: 95vw;
      height: 85vh;
    }
    .input_and_img {
      flex-direction: column;
      width: 100%;
    }
    .uploaded-img-box {
      margin: 0 0 2rem 0;
      height: 60px;
    }
    .uploaded-image {
      width: 80%;
    }
  }

  @media (min-width: 2000px) {
    .hero {
      width: 35vw;
      height: 55vh;
    }
    // custom CSS
  }
`;

const StyledTextField = styled(TextField)`
  & input {
    border-radius: 10px;
  }
  & .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border-color: #d9d8d8;
      border-width: 1px;
      border-radius: 10px;
      color: #d9d8d8;
    }
  }
  & .MuiButtonBase-root {
    background-color: black;
  }
`;

export default CreateBadge;
