import React from "react";
import { useState, useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { Navigate, useNavigate } from "react-router-dom";
import { getUser } from "../../Services/apiClient";
import { getTemplates } from "../../Services/apiClient";
import styled from "styled-components";
import { m, motion, useAnimation } from "framer-motion";
import logo from "../../assets/geshdologoWhite.png";
import { getSpecificTemplate } from "../../Services/apiClient";

const TemplateView = () => {
  const [currentUser, setCurrentUser] = useState({});
  const [templates, setTemplates] = useState([]);
  const [loading, setLoading] = useState(false);
  let navigate = useNavigate();

  const getCurrentUser = async () => {
    const user = await getUser("firestore/whoAmI");

    setCurrentUser(user);
    return user;
  };
  useEffect(() => {
    getCurrentUser().then((user) => {
      if (user.role === "admin") {
        getTemplates("firestore/templates").then(
          (templates) => setTemplates(templates),
          setTimeout(() => {
            setLoading(true);
          }, 1500)
        );
      }
    });
  }, []);

  if (loading) {
    return (
      <>
        {currentUser && currentUser.role && (
          <>
            <h2 style={{ padding: "5vh 1vh 1vh 5vw", color:"black", fontWeight:"400", fontSize:"2em" }}>Badge templates <span><img src="https://cdn-icons-png.flaticon.com/512/559/559907.png" style={{width:"3vw"}} alt="" /></span></h2>
            <StyledOverallViewContainer>
              <div className="hero">
                <motion.div className="grid">
                  {templates.map((template, id) => (
                    <motion.div
                      initial={{ y: 100, opacity: 0 }}
                      whileInView={{ opacity: 1, y: 0 }}
                      transition={{
                        type: "spring",
                        stiffness: 60,
                      }}
                      viewport={{ once: true }}
                      key={id}
                      className="card"
                      onClick={()=> navigate("/specificTemplate/"+template.id)}
                    >
                      <div className="img_and_cardinfo">
                        <img src={template.img} alt="badge" />
                        <div className="card-info">
                          <p>{template.date}</p>

                          <p>{template.info}</p>
                        </div>
                      </div>
                      <div className="banner">
                        <img className="logo" src={logo} alt="logo" />
                      </div>
                    </motion.div>
                  ))}
                </motion.div>
              </div>
            </StyledOverallViewContainer>
          </>
        )}
      </>
    );
  } else {
    return (
      <Box
        sx={{
          height: "100vh",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress color="secondary" />
      </Box>
    );
  }
};

export default TemplateView;

const StyledOverallViewContainer = styled.div`
  height: 100vh;
  display: flex;
  /* align-items: center; */
  justify-content: center;

  .hero {
    overflow: auto;
    width: 90vw;
    height: 80vh;

    border-radius: 20px;

    margin: 3rem auto;
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 4rem;
    margin: 5rem 2rem;
  }
  .card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 18rem;
    border-radius: 5px;
    background: #fdfdfd;
    border: 2px solid #899fd4;
  }
  .img_and_cardinfo {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    padding: 1rem;
  }
  .card-info {
    background: #1b1b1b;
    padding: 1rem;
    border-radius: 5px;
    color: #fdfdfd;
    margin-left: 2rem;
  }
  p {
    padding-bottom: 5px;
    font-size: 1rem;
  }
  p:first-child {
    font-size: 0.8rem;
  }
  p:nth-child(3) {
    font-size: 1rem;
  }
  img {
    width: 6rem;
  }
  .banner {
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(-135deg,#899fd4,#a389d4);
    height: 4rem;
    width: 100%;
  }
  .logo {
    width: 40%;
  }
`;
