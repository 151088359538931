import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
/* import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker"; */
import * as React from "react";
import "rsuite/dist/rsuite.min.css";
import { LicenseInfo } from "@mui/x-license-pro";
import { DateRangePicker } from 'rsuite';

LicenseInfo.setLicenseKey("YOUR_LICENSE_KEY");

const Filter = (props) => {
  LicenseInfo.setLicenseKey("YOUR_LICENSE_KEY");
  /*   var isBetween = require('dayjs/plugin/isBetween')
dayjs.extend(isBetween) */

  const [issuer, setIssuer] = useState("any");
  const [receiver, setReceiver] = useState("any");
  const [date, setDate] = useState([
    new Date(new Date().setMonth(new Date().getMonth() - 2)),
    new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
      
  ]);
  const [badgeType, setBadgeType] = useState("any");
  const [openDate, setOpenDate]=useState(false)

  const handleChange = async (event) => {
    if (event.target.name === "issuer") {
      const newIssuer = event.target.value === "" ? "any" : event.target.value;
      setIssuer(newIssuer);

      props.getData({ issuer: newIssuer, receiver: receiver, date: date });
    }

    if (event.target.name === "receiver") {
      const newReceiver =
        event.target.value === "" ? "any" : event.target.value;
      setReceiver(newReceiver);
      props.getData({ issuer: issuer, receiver: newReceiver, date: date });
    }
  };

  const handleDateChange = (newValue) => {
   
    const date1 = new Date(newValue[0]);
    const date2 = new Date(newValue[1]);
   
   /*  date1.setDate(date1.getDate() + 1);
    date2.setDate(date2.getDate() + 1); */
  /*   const startDate = date1.toISOString().slice(0, 10);
    const endDate = date2.toISOString().slice(0, 10); */
    /* console.log(startDate, " ", endDate) */

    setDate([date1, date2]);
    props.getData({
      issuer: issuer,
      receiver: receiver,
      date: [date1, date2],
    });
  };

  return (
    <div className="filterWrapper" style={styles.filterWrapper}>
      <FormControl
        fullWidth
        sx={{
          
          backgroundColor: "white",
          borderRadius: "4px",
        }}
      >
        <InputLabel
          id="demo-simple-select-label"
          sx={{
            
          }}
        >
          Badge type
        </InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          name="badgeType"
          label="Badge type"
          onChange={handleChange}
          
        >
          <MenuItem value={"1 type"}>1 type</MenuItem>
          <MenuItem value={"2 type"}>2 type</MenuItem>
        </Select>
      </FormControl>
      <TextField
        id="outlined-number"
        label="Issuer"
        name="issuer"
        type="text"
        sx={{
          "& .MuiInputBase-input": {
          
            backgroundColor: "white",
            borderRadius: "4px",
          },
         
        }}
        onChange={(e) => handleChange(e)}
        InputLabelProps={{
          shrink: true,
        }}
      />

      {props.user.role === "admin" && (
        <TextField
          id="outlined-number"
          label="Receiver"
          name="receiver"
          type="text"
          sx={{
            "& .MuiInputBase-input": {
             
              backgroundColor: "white",
              borderRadius: "4px",
            },
          
          }}
          onChange={(e) => handleChange(e)}
          InputLabelProps={{
            shrink: true,
          }}
        />
      )}

      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div>
        <DateRangePicker  showOneCalendar open={openDate} onOk={(dates)=>handleDateChange(dates, "hi")}
         defaultCalendarValue={date} onClick={()=> setOpenDate(!openDate)} value={date} className="overallDatePicker"
         />
        </div>
      </LocalizationProvider>
    </div>
  );
};

export default Filter;

const styles = {
  filterWrapper: {
    width: "85vw",
    display: "grid",
    gridTemplateColumns: "repeat(4, 1fr)",
    columnGap: "4vw",
    color: "white",
    paddingLeft: "3vw",
    paddingTop: "2vh",
    justifyContent: "center",
    itemsAlign: "center",
  },
};
