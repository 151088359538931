import React from 'react';
// import { Routes, Route, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import { Routes, Route, useLocation } from 'react-router-dom';

import CreateBadge from './CreateBadge';
import UserView from './Userview';
import LandingPage from './LandingPage/index';
import Nav from './Nav/Nav';
import OverallView from './OverallView';
import Profile from './Profile/Profile';
import SpecificTemplate from "./Template/SpecificTemplate"
//import SpecificBadge from './SpecificBadge';

const AnimatedRoutes = () => {
  const location = useLocation();
  return (
    <AnimatePresence
      exitBeforeEnter
      onExitComplete={() => window.scrollTo(0, 0)}
    >
      <Routes location={location} key={location.pathname}>
        <Route path='/create' element={<CreateBadge />} />
        <Route path='/specificTemplate/:id' element={<SpecificTemplate />} />

        <Route path='/overallview' element={<OverallView />} />
        <Route path='/' element={<LandingPage />} />
        
        
        
      </Routes>
    </AnimatePresence>
  );
};

export default AnimatedRoutes;
