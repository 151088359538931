import { useState, useEffect } from 'react';
import styled from 'styled-components';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import {
  // BrowserRouter as Router,
  // Switch,
  // Route,
  // Link,
  useParams,
} from 'react-router-dom';
import badge4 from '../../badge4.png';
import logo from '../../assets/geshdologoWhite.png';

const BadgePreview = (props) => {
    return (
   
       
              <div className='card' style={styles.card}>
                 <div className="badgeInfo" style={styles.badgeInfo}>
                    <div className="img">
                        <img src={props.img || badge4} alt='badge' style={{height:"20vh"}} />
                    </div>
                 
                 <div className="info" style={styles.info}>
                    <p>{props.date}</p>
                    <p style={{ fontWeight: 'bold' }}>{props.name}</p>
                  <p>{props.info}</p>
                 </div>
                
                 </div>
                 <div className="banner" style={styles.banner}>
                 <img className='logo' src={logo} alt='logo' style={styles.logo} />
                 </div>
              </div>
            
          
        
    );
}

export default BadgePreview;

const styles={
    badgeInfo:{
    display:"flex",
    flexDirection:"row",
    justifyContent:"space-between",
    gap:"3vw",
    width:"100%",
    padding:"4vh 2vw 4vh 2vw"
    },
card:{
    borderRadius:"10px",
    border: "2px solid #899fd4",
    display:"flex",
    flexDirection:"column",
    height:"auto",
    justifyContent:"space-between",
    padding:"0px",

},
banner:{
    width:"100%",
    background: "linear-gradient(-135deg,#899fd4,#a389d4)",
    display:"flex",
    justifyContent:"center",
    padding:"2vh"
},
logo:{
    height:"5vh"
},
info:{
    backgroundColor:"black",
    borderRadius:"10px",
    display:"flex",
    flexDirection:"column",
    gap:"2vh",
    width:"25vh",
    padding:"3vh 2vw 3vh 2vw",
    color:"white",
    overflowWrap: "break-word",
    fontSize:"1.8vh"
  
   
   
}
}