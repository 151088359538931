import styled from "styled-components";
import { useState, useEffect } from "react";
import { getUser } from "../../Services/apiClient";
import AdminBtns from "./AdminBtns";
import UserBtns from "./UserBtns";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";

import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";

const Nav = () => {
  const [currentUser, setCurrentUser] = useState({});
  const [loading, setLoading] = useState(false);

  async function getCurrentUser() {
    const user = await getUser("firestore/whoAmI");

    setCurrentUser(user);
  }

  useEffect(() => {
    getCurrentUser().then(
      setTimeout(() => {
        setLoading(true);
      }, 1500)
    );
  }, []);

  function getUserName() {
    const name = currentUser.email.split(".")[0];
    const nameCapital = name.charAt(0).toUpperCase() + name.slice(1);
    return nameCapital;
  }

  return (
    <>
      {currentUser && currentUser.role && (
        <Box sx={{ flexGrow: 1,}}>
          <AppBar position="static" sx={{background: "#3f4d67"}}>
            <Toolbar>
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
              >
                {/* <MenuIcon /> */}
              </IconButton>
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                Welcome {getUserName()}
              </Typography>

              {currentUser.role === "admin" && <AdminBtns />}
              {currentUser.role === "user" && <UserBtns />}
            </Toolbar>
          </AppBar>
        </Box>
      )}
    </>
  );
};

const styles = {};

export default Nav;
